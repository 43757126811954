const RoutePath = {
  wildcardView: '/*',
  homeView: '/',
  serviceView: '/tjanster',
  cooperativeView: '/samarbeten',
  contactView: '/kontakt',
  supportView: '/support',
  processView: '/process',
  notFoundView: '/404',
  receivedView: '/tack',
  teamView: '/team'
};

export default RoutePath;